<template>
    <div class="fixed top-0 left-0 z-10 bg-white w-full flex flex-col items-center border-b">
        <div class="flex w-full">

            <router-link to="/">
                <div class="py-2 w-12 h-14" style="background-image: url(http://cdn.onlinewebfonts.com/svg/img_420742.png); background-repeat: no-repeat; background-size: 20%; background-position: center;"></div>
            </router-link>
            <div class="py-3 px-4 text-2xl">Комбо Омлет</div>
        </div>
    </div>
    <div class="pt-20 bg-gray-50 flex flex-col w-full">
        <div v-if="iscombosloaded">
            <transition-group name="post-list">
                <div v-for="combo in combos" :key="combo.id" class="w-full mb-8 rounded-md shadow-md mx-4 bg-white " >
                    <div class="w-full relative">
                        <div class="flex transition-all" :class="[ selectedItem == combo.id ? 'flex-col' : 'flex-row']">
                            <div @click="expandItem(combo)" :style="{ 'background': 'url('+combo.main_image?.url+') no-repeat'}" 
                                style="background-position: left center; background-size: 140%"
                                class="transition-all meal-image"
                                :class="[ selectedItem == combo.id ? 'w-full expanded' : 'w-2/5']">
                            </div>
                            <div class="transition-all p-2" :class="[ selectedItem == combo.id ? 'w-full p-4' : 'w-3/5']">
                                <div>
                                    <div @click="expandItem(combo)" class="transition-all" :class="[ selectedItem == combo.id ? 'text-2xl' : 'text-lg']">
                                        {{ combo.name }}
                                        <div class="text-yellow-500 text-2xl">{{ combo.price }}тг</div>
                                        <div class="text-black text-base" style="text-decoration: line-through">{{ combo.full_price }}тг</div>
                                    </div>
                                    <div @click="expandItem(combo)" class="transition-all pr-4" :class="[ selectedItem == combo.id ? 'text-md' : 'text-xs']">
                                        {{  combo.description  }}
                                    </div>
                                    <div class="transition-all text-xs flex py-2">
                                        <div v-for="meal in combo.meals">
                                            <div class="flex w-full items-center">
                                                <div class="rounded-full bg-gray-100 h-6 w-6"><img :src="meal.main_image?.url" class="rounded-full bg-gray-100" /></div>
                                                <!-- <div class="w-4/5 pl-1">{{ meal.name }}</div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="transition-all" :class="[ selectedItem == combo.id ? 'text-md py-4' : 'hidden']">
                                        <div class="py-4">В составе:</div>
                                        <div v-for="meal in combo.meals" class="w-full flex items-center">
                                            <div class="rounded-full bg-gray-100 h-6 w-6 mr-2"><img :src="meal.main_image?.url" class="rounded-full bg-gray-100" /></div>
                                            <div>
                                                {{ meal.name }} x{{ meal.pivot.amount }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-full flex justify-center">
                                        <div class="w-2/3 py-4 text-md">
                                            <div v-if="!this.$store.getters.countItemInBasket(combo.id)" class="flex w-full text-center">
                                                <div class="py-2 bg-yellow-100 border border-yellow-100 rounded-lg w-full" @click="addToCart(combo)">{{ emoji[Math.floor(Math.random()*emoji.length)] }} заказать</div>
                                            </div>
                                            <div v-else class="flex w-full text-2xl">
                                                <div class="w-1/3 py-1 px-2 bg-gray-100 text-red-700 font-semibold text-center rounded-tl-lg rounded-bl-lg" @click="removeFromCart(combo)">-</div>
                                                <div class="w-1/3 py-1 px-2 text-center border-t border-b" :class="{'bounce animated': animated}" @animationend="animated = false">{{ this.$store.getters.countItemInBasket(combo.id).amount }}</div>
                                                <div class="w-1/3 py-1 px-2 bg-green-100  text-green-700 font-semibold text-center rounded-tr-lg rounded-br-lg" @click="addToCart(combo)">+</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>
        <div v-else class="w-full flex flex-col justify-center items-center py-20">
            <img src="@/assets/pizza_loading_1.gif" class="w-2/5" />
            <div class="p-8">загрузка...</div>
        </div>
    </div>
    <div class="py-20 bg-gray-50 w-full px-8">
        <i>Кликнув по названию или фото - можно получить дополнительную информацию</i>
    </div>
</template>

<script>
import axios from 'axios';
import api_route from '../router/api_routes';

export default {
    data () {
        return {
            branch_id: 1,
            combos: [],
            selectedItem: 0,
            animated: false,
            iscombosloaded: false,
            emoji: ['😋', '😉', '😀', '😃', '😄', '😁', '😆', '😅', '😊', '😇', '🙂', 
                    '😉', '😌', '😍', '🥰', '😘', '😗', '😙', '😚', '😋', '😛', '😝', '😜', '🤪', 
                    '❤️', '🧡', '💛', '💚', '💙', '💜', '🖤'],
        }
    },
    methods: {
        addToCart(item){
            this.$root.increasing = true
            this.$store.commit('addToBasket', { item, is_combo: 1, details: '' })
        },
        removeFromCart(item){
            this.$root.decreasing = true
            this.$store.commit('removeFromBasket', { item, is_combo: 1, details: '' })
        },
        expandItem(combo){
            if(this.selectedItem == combo.id) return this.selectedItem = 0
            return this.selectedItem = combo.id
        },
        getCombosAll(){
            this.selectedItem = 0
            this.iscombosloaded = false
            axios
                .get(api_route('combos_all', [ this.branch_id ]))
                .then((res)=>{
                    this.combos = res.data
                    this.iscombosloaded = true
                })
        },
    },
    mounted() {
        if(this.$route.params.branch_id){
            this.branch_id = this.$route.params.branch_id
        }
        this.getCombosAll()
    },
}
</script>

<style scoped>

    .meal-image.expanded{
        background-position: center top !important; 
        background-size: contain !important;
        height: 360px;
    }
    .post-list {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .post-list-enter-active,
    .post-list-leave-active {
        transition: all .3s ease
    }

    .post-list-enter-from{
        opacity: 0;
        transform: translateX(-100%);
    }
    .post-list-leave-to {
        opacity: 0;
        transform: translateX(100%);
    }

    .post-list-move {
        transition: all .3s ease
    }
    .bounce {
        animation: bounce .5s;
    }

    @keyframes bounce {
        0% {
            font-size: 1rem;
        }
        50% {
            font-size: 2rem;
        }
        100% {
            font-size: 1rem;
        }
    }
</style>