<template>
    <div class="fixed top-0 left-0 z-10 bg-white w-full flex flex-col items-center border-b">
        <div class="flex w-full">

            <router-link to="/">
                <div class="py-2 w-12 h-14" style="background-image: url(http://cdn.onlinewebfonts.com/svg/img_420742.png); background-repeat: no-repeat; background-size: 20%; background-position: center;"></div>
            </router-link>
            <div class="py-3 px-4 text-2xl">Оставить отзыв</div>
        </div>
    </div>
    <div class="pt-20 bg-gray-50 flex flex-col w-full">
        <my-phone-input ref="user_phone" v-model="user.phone" class="py-4 pl-4 border w-full text-xl outline-none" placeholder="87471112233" required />
        <input v-model="order.id" class="py-3 pl-4 border w-full outline-none" placeholder="Номер заказа" type="tel"/>
        <div class="p-2 flex flex-col">
            <div class="px-2">Ваша оценка:</div>
            <div class="p-2 flex flex-col justify-between">
                <div class="flex flex-row items-center py-3">
                    <label class="px-2 w-2/3 text-lg">Кухня:</label>
                    <input type="range" v-model="rating.kitchen" max="5" min="1" :val="rating.kitchen"/>
                    <div class="text-lg">{{ rating.kitchen }}</div>
                </div>
                <div class="flex flex-row items-center py-1">
                    <label class="px-2 w-2/3 text-lg">Доставка:</label>
                    <input type="range" v-model="rating.courier" max="5" min="1" :val="rating.courier"/>
                    <div class="text-lg">{{ rating.courier }}</div>
                </div>
                <div class="flex flex-row items-center py-3">
                    <label class="px-2 w-2/3 text-lg">Оператор:</label>
                    <input type="range" v-model="rating.operator" max="5" min="1" :val="rating.operator"/>
                    <div class="text-lg">{{ rating.operator }}</div>
                </div>
            </div>
        </div>
        <textarea v-model="rating.body" class="py-3 pl-4 border w-full outline-none" placeholder="Ваш отзыв о заказе..."></textarea>
        <div v-if="sendingError" class="bg-green-100 text-center p-4 text-xl mt-4">
            {{ sendingError }}
        </div>
        <div v-else class="w-full p-4 bg-white">
            <div v-if="!is_sending" class="py-4 w-full rounded-md bg-yellow-300 text-center text-xl" @click="sendRating">🔥 Отправить в Омлет</div>
        </div>
    </div>
    <div class="py-20 bg-gray-50 w-full px-8 text-center">
        <i>Каждый отзыв проверяется вручную руководством, с любовью к вам и нашему делу. Спасибо, за вашу поддержку ❤️</i>
    </div>
</template>

<script>
import axios from 'axios';
import api_route from '../router/api_routes';

export default {
    data: () => {
        return {
            order: { 
                id: null,
            },
            user: { 
                phone: null,
            },
            rating: {
                kitchen: 5,
                courier: 5,
                operator: 5,
                body: null,
            },
            is_sending: false,
            sendingError: null,
            emoji: ['😋', '😉', '😀', '😃', '😄', '😁', '😆', '😅', '😊', '😇', '🙂', 
                    '😉', '😌', '😍', '🥰', '😘', '😗', '😙', '😚', '😋', '😛', '😝', '😜', '🤪', 
                    '❤️', '🧡', '💛', '💚', '💙', '💜', '🖤'],
        }
    },
    methods: {
        sendRating(){

            let params = {
                id: this.order.id,
                kitchen: this.rating.kitchen,
                operator: this.rating.operator,
                courier: this.rating.courier,
                body: this.rating.body,
            }

            axios
                .post(api_route('order_rate'), params)
                .then((res)=>{
                    if(res.data){
                        if(res.data.code != 200){
                            this.sendingError = res.data.message
                        } else
                        if(res.data.code == 200){
                            this.sendingError = res.data.message
                        }
                    }
                })
                .finally(()=> this.is_sending = false);
        }
    },
    mounted() {
    },
}
</script>

<style scoped>
textarea {
    transition: all .3s;
}
textarea:focus { min-height: 8rem}
input[type=range]{
    -webkit-appearance: none;
    background-color: #eee;
    border-radius: 16px;
    margin: 0 10px;
}

input[type=range]::-webkit-slider-runnable-track {
    /* width: 100px; */
    height: 32px;
    /* background: #ddd; */
    /* background: #eee; */
    border: none;
    border-radius: 16px;
    padding: 2px 8px;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 26px;
    width: 26px;
    margin-top: -0px;
    border-radius: 50%;
    background-color: white;
    /* 
    border-color: goldenrod;
    border: 2px solid goldenrod; */
    /* background-image: url(https://img.icons8.com/?size=24&id=83166&format=png); */
    background-image: url(https://img.icons8.com/?size=60&id=5Xt0r1FpeKPY&format=png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    transition: all .3s;
}

input[val="1"]::-webkit-slider-thumb {
    /* height: 14px;
    width: 14px; */
    margin-top: 2px;
    transform: rotate(-180deg);
    transform: rotate(-180deg) translateX(10px);
}
input[val="2"]::-webkit-slider-thumb {
    /* height: 14px;
    width: 14px;*/
    margin-top: 3px;
    transform: rotate(-90deg);
}
input[val="3"]::-webkit-slider-thumb {
    /* height: 16px;
    width: 16px;
    margin-top: -4px; */
    transform: rotate(0deg);
}
input[val="4"]::-webkit-slider-thumb {
    height: 30px;
    width: 30px;
    margin-top: -2px;
    transform: rotate(15deg);
}
input[val="5"]::-webkit-slider-thumb {
    height: 36px;
    width: 36px;
    margin-top: -8px;
    transform: rotate(25deg) translateX(10px);
    /* background-color: goldenrod; */
}

input[type=range]:focus {
    outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ccc;
    /* background: #d47f56; */
}
</style>