<template lang="">
    <div v-if="$route.meta.hasbasket">
        <div class="rightnav" :class="[ is_navbaropen ? 'open' : 'closed']">
            <div class="flex h-full" style="width: 100%; height: 100%">
                <div v-if="is_navbaropen" class="absolute top-0 w-1/5 h-full flex justify-center items-center opacity-30" style="right: 100%" @click="toggleRightNav">
                    <div :class="[ is_navbaropen ? '' : 'hidden']" style="width: 24px; height: 24px;">
                        <img src="http://cdn.onlinewebfonts.com/svg/img_420742.png" style="height: 24px"/>
                    </div>
                </div>
                <div v-if="!is_sendingroder" class="w-full">
                    <div class="w-full px-4 h-full overflow-y-auto bg-white shadow-lg flex flex-col relative" v-if="$store.getters.basketItems.length > 0" >
                        <!-- <div>order history</div> -->
                        <div class="pt-4" style="max-height: 100%; overflow-y: auto">
                            <div>В заказе: {{ this.$store.getters.basketSumm }}тг</div>
                            <div class="flex flex-wrap pt-4 pb-32">
                                <div v-for="item in $store.getters.basketItems" class="w-1/2">
                                    <div class="w-full flex justify-center">
                                        <div class="relative w-full flex justify-center">
                                            <div class="absolute left-0 top-2 flex justify-center items-center w-full">
                                                <div class="bg-black text-yellow-300 rounded-md px-2 text-xs">{{ item.name }}</div>
                                            </div>

                                            <img :src="item.image" class="w-28 h-28 rounded-full"/>
                                            <!-- <div class="absolute top-2 right-0 bg-gray-100 text-gray-500 w-8 h-8 rounded-full flex justify-center items-center" @click="removeItemFromBasket(item)">x</div> -->
                                            <div class="absolute left-0 flex justify-center items-center w-full" style="bottom: 0">
                                                <div class="flex bg-yellow-300 rounded-full  justify-center items-center">
                                                    <div class="bg-black text-yellow-300 w-8 h-8 rounded-tl-full rounded-bl-full  flex justify-center items-center active:bg-gray-500" @click="decreaseAmount(item)">-</div>
                                                    <div class="text-center w-12 rounded-full bg-yellow-300">
                                                        {{ item.amount }}
                                                    </div>
                                                    <div class="bg-black text-yellow-300 w-8 h-8 rounded-tr-full rounded-br-full flex justify-center items-center" @click="increaseAmount(item)">+</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="absolute bottom-0 left-0 w-full p-4 bg-white">
                            <div class="w-full">
                                <div class="py-2 w-full rounded-md bg-yellow-300 text-center text-2xl" @click="goToBasket">🔥 Кухне &rarr;</div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="pt-4 w-full h-full flex flex-col justify-center items-center bg-gray-100">
                        <img src="https://cdn.dribbble.com/users/722835/screenshots/2199463/media/d47c092edf96895673380f582241b84e.gif" class="rounded-full" style="width: 75%"/>
                        <div class=" pt-16 text-2xl text-center">
                            <div class="py-4 ">В заказе нет блюд</div>
                            <div class="py-2 px-4 rounded-md bg-yellow-300" @click="is_navbaropen = false" >&larr; вернуться в меню</div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <i>отправляем заказ</i>
                </div>
            </div>
            <div class="absolute top-2 flex bg-white rounded-full p-2" style="right: 105%" @click="toggleRightNav">
                <div style="width: 24px; height: 24px;">
                    <img src="https://img.icons8.com/ios/256/fast-moving-consumer-goods.png" style="width: 24px; height: 24px"/>
                </div>
                <div class="ml-2 px-2 flex justify-center items-center rounded-full bg-yellow-300 overflow-y-hidden" v-show="!is_navbaropen">
                    <div :class="[ increasing ? 'increasing' : decreasing ? 'decreasing' : '' ]" @animationend="increasing = false, decreasing = false">{{ this.$store.getters.basketSumm }}тг</div>
                </div>
            </div>
        </div>
    </div>
    <div class="app" :class="[ !is_navbaropen ? 'active' : 'backgrounded']">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    data: ()=> {
        return {
            user: {
                phone: null
            },
            address: {
                street: '',
                building: '',
                appartment: '',
                entrance: '',
                floor: '',
            },
            payment: {
                transfer: 0,
                cash: 0,
            },
			is_navbaropen: false,
            is_sendingroder: false,
            sendingError: '',
            increasing: false,
            decreasing: false,
        }
    },
    methods:{
		toggleRightNav(){
            this.is_navbaropen = !this.is_navbaropen
		},
        increaseAmount(item){
            this.$store.commit('increaseAmount', item)
        },
        decreaseAmount(item){
            this.$store.commit('decreaseAmount', item)
        },
        goToBasket(){
            this.is_navbaropen = false
            this.$router.push({ name: 'Basket'});
        }
    },
    mounted(){
    },
}
</script>
<style>
     * {
        margin: 0; 
        padding: 0; 
        box-sizing: border-box;
        font-family: 'Ubuntu Condensed', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
    }
    html, body {
        height: 100%;
        width: 100%;
    }
    #app{
        background-color: #fff;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
    }
    .app {
        display: flex; 
        flex-direction: column;
        width: 100%;
        overflow-x: hidden;
        transition: all .3s;
        background-color: white;
    }
    .app.backgrounded {
        transform: translateX(100%);
        height: 100%;
        overflow-y: hidden;
        /* border-radius: 12px; */
        /* box-shadow: 0 0 10px #aaa */
    }

    .rightnav {
        position: fixed;
        top:0;
        background: white;
        z-index: 20;
        transition: all .3s;
        width: 80%;
        height: 100%;
    }
    .rightnav.open{
        left: 20%;
    }

    .rightnav.closed{
        left: 100%;
    }

    .decreasing {
        animation: decreasing .3s;
    }

    @keyframes decreasing {
        0% {
            transform: translateY(70%);
            opacity: 0;
        }
        50% {
            transform: translateY(30%);
            opacity: 1;
        }
        100% {
            transform: translateY(0%);
        }
    }
    
    .increasing {
        animation: increasing .3s;
    }
    
    @keyframes increasing {
        0% {
            transform: translateY(-70%);
            opacity: 0;
        }
        50% {
            transform: translateY(-30%);
            opacity: 1;
        }
        100% {
            transform: translateY(0%);
        }
    }
</style>