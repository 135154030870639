<template>
    <div class="fixed top-0 left-0 z-10 bg-white w-full flex flex-col items-center border-b">
        <div class="flex w-full">
            <div @click="hasHistory() ? $router.back() : $router.push('/')">
                <div class="py-2 w-12 h-14" style="background-image: url(http://cdn.onlinewebfonts.com/svg/img_420742.png); background-repeat: no-repeat; background-size: 20%; background-position: center;"></div>
            </div>
            <div class="py-3 px-4 text-2xl">Ваш заказ</div>
        </div>
        <div class="flex w-full">
            <div class="flex px-2 w-full border-t items-center overflow-x-auto py-2 justify-between">
                <div class="py-2 px-3 rounded-full text-md whitespace-nowrap"
                    :class="[ current_sector == 0 ? 'bg-yellow-300' : $store.getters.basketSumm > 0 ? 'bg-green-100 text-green-500' : 'bg-gray-100 text-red-500']"
                    @click="current_sector = 0">Блюда</div>
                <div class="py-2 px-3 rounded-full text-md whitespace-nowrap"
                    :class="[ current_sector == 1 ? 'bg-yellow-300' : user.phone ? 'bg-green-100 text-green-500' : 'bg-gray-100 text-red-500']"
                    @click="current_sector = 1">Телефон</div>
                <div class="py-2 px-3 rounded-full text-md whitespace-nowrap"
                    :class="[ current_sector == 2 ? 'bg-yellow-300' : address.street && address.building ? 'bg-green-100 text-green-500' : 'bg-gray-100 text-red-500']"
                    @click="current_sector = 2">Адрес</div>
                <div class="py-2 px-3 rounded-full text-md whitespace-nowrap"
                    :class="[ current_sector == 3 ? 'bg-yellow-300' : 'bg-gray-100']"
                    @click="current_sector = 3">Оплата</div>
            </div>
        </div>
    </div>
    <div  class="pt-32 flex flex-col w-full">
        <section v-show="current_sector == 0">
            <div v-if="$store.getters.basketItems.length > 0">
                <div v-for="item in $store.getters.basketItems" class="w-full mb-2 rounded-bl-full rounded-tl-full shadow-md mx-2 bg-white overflow-hidden" >
                    <div class="w-full relative">
                        <div class="flex flex-row">
                            <div :style="{ 'background': 'url('+item.image+') no-repeat'}" 
                                style="background-position: left center; background-size: cover"
                                class="meal-image w-1/5">
                            </div>
                            <div class="p-2 w-4/5 flex items-center">
                                <div class="w-2/3 text-md flex items-center">
                                    {{ item.name }}
                                    <div class="px-2">{{ item.price }}тг</div>
                                </div>
                                <div class="w-1/3  text-md pr-1">
                                    <div class="flex w-full text-md items-center">
                                        <div class="w-1/3 py-1 px-2 bg-gray-100 text-red-700 font-semibold text-center rounded-tl-lg rounded-bl-lg border" @click="removeMealFromCart(item)">-</div>
                                        <div class="w-2/3 py-1 px-2 text-center border-t border-b" :class="{'bounce animated': animated}" @animationend="animated = false">{{ item.amount }}</div>
                                        <div class="w-1/3 py-1 px-2 bg-green-100  text-green-700 font-semibold text-center rounded-tr-lg rounded-br-lg border" @click="addMealToCart(item)">+</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-2xl py-2 px-4">В заказе: {{ $store.getters.basketSumm }}тг</div>
                <div class="w-full pt-8 px-4">
                    <div class="w-full">
                        <div class="py-2 w-full rounded-md bg-yellow-300 text-center" @click="current_sector = 1">Продолжить &rarr;</div>
                    </div>
                </div>
            
            </div>
            <div v-else class="w-full flex flex-col justify-center items-center py-20">
                <img src="@/assets/empty.png" class="w-2/5" />
                <div class="p-8">В вашем заказе пусто</div>
                <div class="p-8">Добавьте блюда из <router-link to="/">нашего меню</router-link></div>
            </div>
        </section>

        <section v-show="current_sector == 1">
            <div class="">
                <my-phone-input ref="user_phone" v-model="user.phone" class="py-4 px-8 border w-full text-3xl outline-none" placeholder="87471112233" required />
            </div>
            <div v-if="user.phone" class="w-full pt-8 px-4">
                <div class="w-full">
                    <div class="py-2 w-full rounded-md bg-yellow-300 text-center" @click="current_sector = 2">Продолжить &rarr;</div>
                </div>
            </div>
            <div class="mt-10 py-8 bg-gray-50 w-full px-8 flex flex-col text-gray-500">
                <i>Чтобы система смогла точно определить ваш аккаунт, вводите номер телефона:</i>
                <i>- без лишних символов +().</i>
                <i>- без пробелов</i>
                <i>- с кодом 8</i>
                <i>- только цифры</i>
                <i class="pt-2">например, 87471092682</i>
            </div>
            
        </section>
        <section v-show="current_sector == 2">
            <div class="flex text-lg">
                <!-- <select class="py-2 pl-4 border w-4/5" placeholder="Улица">
                    <option value="">Айманова</option>
                    <option value="">Аймаутова</option>
                </select> -->
                <input ref="address_street" v-model="address.street" class="py-3 pl-4 border w-3/4 outline-none" placeholder="Улица" @keyup="suggestStreet"/>
                <input ref="address_building" v-model="address.building" class="py-3 pl-4 border w-1/4 outline-none" placeholder="Дом" type="tel"/>
            </div>
            <div class="flex flex-wrap justify-center" style="max-height: 240px; overflow-y: auto">
                <div v-for="street in streetsSuggestions" class="p-1">
                    <div class="py-2 px-4 rounded-full bg-yellow-100" @click="pickStreet(street)">{{ street.name }}</div>
                </div>
            </div>
            <div v-if="buildingSuggestions.length > 0" class="flex p-2 flex-wrap justify-center" style="max-height: 240px; overflow-y: auto">
                <div v-for="building in buildingSuggestions" class="p-1">
                    <div class="py-2 px-4 rounded-full bg-yellow-100" @click="pickBuilding(building)">{{ building.number }}</div>
                </div>
            </div>
            <div class="flex text-lg">
                <input v-model="address.appartment" class="py-3 pl-4 border w-2/4 outline-none" placeholder="Квартира/Офис" type="tel"/>
                <input v-model="address.entrance" class="py-3 pl-4 border w-1/4 outline-none" placeholder="Подъезд" type="tel"/>
                <input v-model="address.floor" class="py-3 pl-4 border w-1/4 outline-none" placeholder="Этаж" type="tel"/>
            </div>
            <div class="flex flex-col text-lg" v-if="address.appartment" >
                <div class="flex items-center py-3 justify-center w-full text-gray-500">
                    Домофон работает: <input v-model="address.has_intercom" class="ml-2" type="checkbox" />
                </div>
            </div>
            <div class="flex flex-col text-lg" >
                <div class="flex items-center justify-center w-full">
                    <input v-model="address.details" class="py-3 pl-4 border w-full outline-none" placeholder="Детали адреса: вход с торца / белые ворота" />
                </div>
            </div>
            <div v-if="address.street && address.building" class="w-full pt-8 px-4">
                <div class="w-full">
                    <div class="py-2 w-full rounded-md bg-yellow-300 text-center" @click="current_sector = 3">Продолжить &rarr;</div>
                </div>
            </div>
        </section>
        <section v-show="current_sector == 3" class="relative pb-32">
            <div class="flex text-lg items-center">
                <div class="w-1/2 p-2">Итого по чеку:</div>
                <div class="py-3 pr-4 mr-4 w-1/2 text-right">{{ $store.getters.basketSumm }}тг</div>
            </div>
            <div class="py-3 text-xs text-center bg-gray-100">
                <i class="text-gray-700 p-2 px-4">*в момент подтверждения вашего заказа итоговая оплата может измениться - она может быть увеличена при платной доставке или уменьшена скидками/акциями.</i>
            </div>
            <div class="flex text-lg items-center py-2">
                <div class="w-1/2 p-2">Наличные:</div>
                <input v-model="payment.cash" class="py-3 pr-4 mr-4 border w-1/2 outline-none text-right rounded-md" placeholder="Наличные" type="number" />
            </div>
            <div class="flex text-lg items-center py-2">
                <div class="w-1/2 p-2">Переводом Kaspi:</div>
                <input v-model="payment.transfer" class="py-3 pr-4 mr-4 border w-1/2 outline-none text-right rounded-md" placeholder="Перевод" type="number"/>
            </div>
            <div class="flex text-md items-center py-2">
                <div class="w-1/2 p-2">Промокод:</div>
                <input v-model="payment.promocode" class="py-3 pr-4 mr-4 border w-1/2 outline-none text-right rounded-md" placeholder="---- ----" type="text"/>
            </div>
            <div class="absolute bottom-0 left-0 w-full p-4 bg-white">
                <div class="w-full">
                    <div v-if="sendingError" class="py-2 text-red-500 py-2 text-lg">
                        {{ sendingError }}
                    </div>
                    <div v-if="!is_sendingorder" class="py-4 w-full rounded-md bg-yellow-300 text-center text-xl" @click="sendOrder">🔥 Отправить в Омлет</div>
                </div>
            </div>
        </section>
        <section v-show="current_sector == 4" class="h-full w-full">
            <div class="h-full w-full flex justify-center items-center">
                <div class="w-full flex flex-col justify-center items-center py-20 px-4">
                    <img src="@/assets/success.gif" class="w-1/5" />
                    <div class="p-8 text-2xl">{{ sendingError }}</div>
                    <router-link class="py-4 w-full rounded-md bg-yellow-300 text-center text-xl" to="/">&larr; вернуться на главную</router-link>
                </div>

            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios';
import api_route from '../router/api_routes';

export default {
    data () {
        return {
            user: { 
                phone: null,
            },
            address: {
                street: '',
                building: '',
                appartment: '',
                entrance: '',
                floor: '',
                has_intercom: false,
                details: '',
            },
            payment: {
                cash: 0,
                transfer: 0,
                promocode: '',
            },
            sendingError: '',
            streets: [],
            streetsSuggestions: [],
            buildingSuggestions: [],
            animated: false,
            is_sendingorder: false,
            current_sector: 0,
            emoji: ['😋', '😉', '😀', '😃', '😄', '😁', '😆', '😅', '😊', '😇', '🙂', 
                    '😉', '😌', '😍', '🥰', '😘', '😗', '😙', '😚', '😋', '😛', '😝', '😜', '🤪', 
                    '❤️', '🧡', '💛', '💚', '💙', '💜', '🖤'],
        }
    },
    methods: {
        addMealToCart(item){
            this.animated = true
            this.$store.commit('increaseAmount', item)
        },
        removeMealFromCart(item){
            this.animated = true
            this.$store.commit('decreaseAmount', item)
        },
        hasHistory () { return window.history.length > 2 },
        getStreets(){
            axios
                .get(api_route('streets_all', [ this.$store.getters.getBranch.city.id ]))
                .then((res)=>{
                    this.streets = res.data
                })
        },
        suggestStreet(){
            let q = this.address.street
            this.streetsSuggestions = this.streets.filter((item) => item.name.toLowerCase().includes(q.toLowerCase()));
        },
        pickStreet(street){
            this.address.street = street.name
            this.streetsSuggestions = []
            this.buildingSuggestions = street.buildings
        },
        pickBuilding(building){
            this.address.building = building.number
            this.buildingSuggestions = []
        },
        sendOrder(){

            if(this.$store.getters.basketSumm == 0){ return this.current_sector = 0; }
            if(!this.user.phone){ this.current_sector = 1; this.$refs.user_phone.$el.focus(); return }
            if(!this.address.street){ this.current_sector = 2; return this.$refs.address_street.$el.focus() }
            if(!this.address.building){ this.current_sector = 3; return this.$refs.address_building.$el.focus() }

            this.is_sendingroder = true

            var params = {'branch_id': this.$store.getters.getBranch.id, 'status_id': 2 };
                                                        
                params.extra_info = {
                    order_source:'m.omelette.kz', 
                }    

                params.customer = {};
                params.address  = {
                    country: {},
                    city: {},
                    street: {},
                    building: {},
                };
                params.date     = {};
                params.items    = {};
                params.payment  = {};

                params.customer.id                   = '';
                params.customer.phone                = this.user.phone;
                
                // params.address.raw                   = ac + ', ' + as + ', ' + ah + '-' + af + ' п' + ap + ' э' + afl;
                params.address.country.name               = 'Казахстан';
                params.address.city.name                  = this.$store.getters.getBranch.city?.name;
                params.address.street.name                = this.address.street;
                params.address.building.number            = this.address.building;
                params.address.building.type              = '';
                params.address.coords                     = '';
                params.address.appartment_number          = this.address.appartment;
                params.address.floor                 = this.address.floor;
                params.address.entrance              = this.address.entrance;
                params.address.has_intercom          = this.address.has_intercom ? 1 : 0;
                params.address.details               = this.address.details;
                
                // params.date.is_preorder              = 0;
                params.date.date                     = 'Сегодня';
                params.date.time                     = 'Сейчас';
                params.date.time_est                 = '60:80';
                
                params.items                         = this.$store.getters.basketItems;
                
                // params.payment.summ_meals_in_list    = 0;
                // params.payment.summ_discounted_meals = 0;
                // params.payment.bonuses_to_minus      = 0;
                // params.payment.bonuses_to_add        = 0;
                params.payment.upcount               = 0;
                params.payment.upcount_comment       = '';
                params.payment.discount              = 0;
                params.payment.discount_comment      = '';
                params.payment.delivery_cost         = '';
                params.payment.transfer              = this.payment.transfer;
                params.payment.cash                  = this.payment.cash;

                params.payment.promocode             = this.payment.promocode

            axios
                .post(api_route('order_store'), params)
                .then((res)=>{
                    if(res.data){
                        if(res.data.code != 200){
                            this.sendingError = res.data.message
                        } else
                        if(res.data.code == 200){
                            this.sendingError = res.data.message
                            this.$store.commit('clearBasket');
                            this.basketMeals = this.$store.getters.basketItems
                            this.current_sector = 4
                        }
                    }
                })
                .finally(()=> this.is_sendingorder = false);
        },

    },
    mounted() {
        this.getStreets()
    },
}
</script>

<style scoped>

    .bounce {
        animation: bounce .5s;
    }

    @keyframes bounce {
        0% {
            font-size: 1rem;
        }
        50% {
            font-size: 1.2rem;
        }
        100% {
            font-size: 1rem;
        }
    }

    input:focus{
        border-top: 1px solid #333;
        border-bottom: 1px solid #333
    }
</style>