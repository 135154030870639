<template>
    <div class="fixed top-0 left-0 z-10 bg-white w-full flex flex-col items-center border-b">
        <div class="flex w-full">

            <router-link to="/">
                <div class="py-2 w-12 h-14" style="background-image: url(http://cdn.onlinewebfonts.com/svg/img_420742.png); background-repeat: no-repeat; background-size: 20%; background-position: center;"></div>
            </router-link>
            <div class="py-3 px-4 text-2xl">{{ tag.name }}</div>
        </div>
        <div class="flex w-full py-2 px-4 border-t items-center overflow-x-auto">
            <div class="py-3 px-5 rounded-full bg-gray-100 text-md" @click="getMealsByTag()"
            :class="[selectedTag == null ? 'bg-yellow-300' : 'bg-gray-100']">Все</div>
            <div v-for="child_tag in children">
                <div @click="getMealsByChildTag(child_tag)" class="ml-4 py-3 px-5 rounded-full text-md whitespace-nowrap" :class="[selectedTag == child_tag.id ? 'bg-yellow-300' : 'bg-gray-100']">{{ child_tag.name }}</div>
            </div>
        </div>
    </div>
    <div  class="pt-36 bg-gray-50 flex flex-col w-full">
        <div v-if="ismealsloaded" class="w-full flex flex-wrap">
            <transition-group name="post-list">
                <div v-for="meal in meals" :key="meal.id" class="w-1/2 mb-8 px-2" >
                    <div class="w-full relative rounded-md shadow-md bg-white overflow-hidden">
                        <div class="">
                            <div @click="expandMeal(meal)" :style="{ 'background': 'url('+meal.main_image?.url+') no-repeat'}" 
                                style="background-position: left center; background-size: 140%"
                                class="w-full aspect-square relative">
                                <img src="https://static.breaktime.kz/uploads/images/breaktime/pixel.png" class="w-full aspect-square"/>
                                <div @click="expandMeal(meal)" class="transition-all bg-white px-2 absolute bottom-2 left-2 rounded-md flex flex-col items-start justify-center text-lg">
                                    <div>{{ meal.name }}</div>
                                    <div style="color: orange; font-size: 16px; font-family: sans-serif">{{ meal.price }} тг</div>
                                </div>
                            </div>
                            <div class="p-0 w-full">
                                <div>
                                    <div class="w-full flex justify-center">
                                        <div class="w-5/6 py-4 text-md">
                                            <div v-if="!this.$store.getters.countItemInBasket(meal.id)" class="flex w-full text-center">
                                                <div class="py-2 bg-yellow-100 border border-yellow-100 rounded-lg w-full" @click="addMealToCart(meal)">{{ emoji[Math.floor(Math.random()*emoji.length)] }} заказать</div>
                                            </div>
                                            <div v-else class="flex w-full text-2xl">
                                                <div class="w-1/3 py-1 px-2 bg-gray-100 text-red-700 font-semibold text-center rounded-tl-lg rounded-bl-lg" @click="removeMealFromCart(meal)">-</div>
                                                <div class="w-2/3 py-1 px-2 text-center border-t border-b" :class="{'bounce animated': animated}" @animationend="animated = false">{{ this.$store.getters.countItemInBasket(meal.id).amount }}</div>
                                                <div class="w-1/3 py-1 px-2 bg-green-100  text-green-700 font-semibold text-center rounded-tr-lg rounded-br-lg" @click="addMealToCart(meal)">+</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="expandMeal(meal)" class="transition-all p-4" :class="[ selectedMeal == meal.id ? '' : 'hidden']">
                                        {{  meal.description || '...' }}
                                    </div>
                                    <div class="transition-all" :class="[ selectedMeal == meal.id && meal.ingredients.length > 0? 'text-md py-4' : 'hidden']">
                                        <div>Ингредиенты:</div>
                                        <div v-for="ingredient in meal.ingredients" class="w-full flex items-center">
                                            <div v-if="ingredient.pivot.is_seen == 1">
                                                {{ ingredient.name }}
                                            </div>
                                            <!-- <div v-if="ingredient.pivot.is_optional == 1">
                                                <div class="rounded-full h-8 w-8 flex items-center justify-center bg-gray-100 ml-4" @click="noIngredient(meal, ingredient)">x</div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>
        <div v-else class="w-full flex flex-col justify-center items-center py-20">
            <img src="@/assets/pizza_loading_1.gif" class="w-2/5" />
            <div class="p-8">загрузка...</div>
        </div>
    </div>
    <div class="py-20 bg-gray-50 w-full px-8">
        <i>Кликнув по названию или фото - можно получить дополнительную информацию</i>
    </div>
</template>

<script>
import axios from 'axios';
import api_route from '../router/api_routes';

export default {
    data () {
        return {
            tag: {
                id: 0,
                name: '...',
                slug: '',
            },
            branch_id: 1,
            children: [],
            meals: [],
            selectedMeal: 0,
            selectedTag: null,
            animated: false,
            ismealsloaded: false,
            emoji: ['😋', '😉', '😀', '😃', '😄', '😁', '😆', '😅', '😊', '😇', '🙂', 
                    '😉', '😌', '😍', '🥰', '😘', '😗', '😙', '😚', '😋', '😛', '😝', '😜', '🤪', 
                    '❤️', '🧡', '💛', '💚', '💙', '💜', '🖤'],
        }
    },
    methods: {
        addMealToCart(item){
            this.$root.increasing = true
            this.$store.commit('addToBasket', { item, is_combo: 0, details: '' })
        },
        removeMealFromCart(item){
            this.$root.decreasing = true
            this.$store.commit('removeFromBasket', { item, is_combo: 0, details: '' })
        },
        expandMeal(meal){
            if(this.selectedMeal == meal.id) return this.selectedMeal = 0
            return this.selectedMeal = meal.id
        },
        getMainTag(){
            axios
                .get(api_route('tag_by_slug', [ this.tag.slug, this.branch_id  ]))
                .then((res)=>{
                    this.tag = res.data
                    this.getMealsByTag() 
                    this.getTagChildren()
                })
        },
        getTagChildren(){
            axios
                .get(api_route('child_tags', [ this.tag.id ]))
                .then((res)=>{
                    this.children = res.data
                })
        },
        getMealsByTag(){
            this.selectedTag = null
            this.selectedMeal = 0
            this.ismealsloaded = false
            axios
                .get(api_route('meals_by_tag', [ this.tag.id ]))
                .then((res)=>{
                    this.meals = res.data
                    this.ismealsloaded = true
                })
        },
        getMealsByChildTag(tag){
            this.selectedTag = tag.id
            this.selectedMeal = 0
            axios
                .get(api_route('meals_by_child_tag', [ tag.id ]))
                .then((res)=>{
                    this.meals = res.data
                })
        },
        // noIngredient(item, ingredient){
            
        // }
    },
    mounted() {
        if(this.$route.params.tag_slug){ 
            this.tag.slug = this.$route.params.tag_slug
            if(this.$route.params.branch_id){
                this.branch_id = this.$route.params.branch_id
            }
            this.getMainTag()
        }
    },
}
</script>

<style scoped>

    .meal-image.expanded{
        background-position: center top !important; 
        background-size: contain !important;
        height: 360px;
    }
    .post-list {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .post-list-enter-active,
    .post-list-leave-active {
        transition: all .3s ease
    }

    .post-list-enter-from{
        opacity: 0;
        transform: translateX(-100%);
    }
    .post-list-leave-to {
        opacity: 0;
        transform: translateX(100%);
    }

    .post-list-move {
        transition: all .3s ease
    }
    .bounce {
        animation: bounce .5s;
    }

    @keyframes bounce {
        0% {
            font-size: 1rem;
        }
        50% {
            font-size: 2rem;
        }
        100% {
            font-size: 1rem;
        }
    }
</style>