const api_routes = {
    'tags_all' : 'https://api.breaktime.kz/api/web/v1/omelette.kz/tags/all/branch/{$0}',
    'tag_by_slug' : 'https://api.breaktime.kz/api/web/v1/omelette.kz/tags/by_slug/{$0}/{$1}',
    'child_tags' : 'https://api.breaktime.kz/api/web/v1/omelette.kz/tags/by_parent/{$0}',
    'meals_by_tag' : 'https://api.breaktime.kz/api/web/v1/omelette.kz/meals/by_tag/{$0}', //tag_id
    'meals_by_child_tag' : 'https://api.breaktime.kz/api/web/v1/omelette.kz/meals/by_child_tag/{$0}', //tag_id
    'order_store' : 'https://api.breaktime.kz/api/web/v1/omelette.kz/order/store',
    'order_rate' : 'https://api.breaktime.kz/api/web/v1/omelette.kz/order/rate', //post
    'order_status' : 'https://api.breaktime.kz/api/web/v1/omelette.kz/order/status', //phone number
    'combos_top' : 'https://api.breaktime.kz/api/web/v1/omelette.kz/combos/top/{$0}/{$1}', //limit branch_id 
    'combos_all' : 'https://api.breaktime.kz/api/web/v1/omelette.kz/combos/all/{$0}', //branch_id 
    'news_latest' : 'https://api.breaktime.kz/api/web/v1/omelette.kz/news/latest/{$0}/{$1}', //limit branch_id 
    'sales_active' : 'https://api.breaktime.kz/api/web/v1/omelette.kz/sales/active/{$0}', //branch_id 
    'streets_all' : 'https://api.breaktime.kz/api/web/v1/omelette.kz/streets/{$0}',
}

function api_route (route_name, params = null) {
    let route_str = api_routes[route_name]
    if(params){
        params.forEach((value, index) => {
            route_str = route_str.replace('{$'+index.toString()+'}', value)
        });
    }
    return route_str;
}

export default api_route