<template lang="">
    <div class="w-full flex flex-col header__bg">
        <div class="w-full flex items-center justify-between">
            <div class="w-full flex flex-col justify-start items-start" style="">
               <div class="pl-4  pb-4 pt-16 ">
                    <img src="https://static.omelette.kz/images/logo.png" style="height: 96px">
                </div>
                <div class="w-10/12 pl-8 pb-8 pt-4 text-3xl white__outline">
                    Доставка пиццы, суши и горячих блюд.
                </div>
                <div class="w-full pb-8 pt-4 text-3xl flex justify-center">
                    <div v-for="_branch in $store.getters.getBranches">
                        <div class="p-4 rounded-lg mx-2" @click="selectBranch(_branch)" :class="[ $store.getters.getBranch.id == _branch.id ? 'bg-yellow-300' : '']">
                            {{ _branch.city.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full">
            <div v-if="istagsloaded" class="w-full flex flex-wrap py-4 px-4 justify-center">
                    <div class="w-1/3">
                        <router-link :to="{ name: 'Combos', params: { branch_id: $store.getters.getBranch.id } }">
                            <div class="flex flex-col rounded-full bg-yellow-300 py-8 m-2 justify-center items-center">
                                <div class="text-4xl">🍱</div>
                                <div class="text-xl pt-4">Комбо</div>
                            </div>
                        </router-link>
                    </div>

                    <div v-for="tag in tags" class="w-1/3">
                        <router-link :to="{name: 'MealsByTag', params: { tag_slug: tag.slug, branch_id: $store.getters.getBranch.id }}">
                        <div class="flex flex-col rounded-full bg-yellow-300 py-8 m-2 justify-center items-center">
                            <div class="text-4xl">{{ tag.emoji }}</div>
                            <div class="text-xl pt-4">{{ tag.name }}</div>
                        </div>
                        </router-link>
                    </div>
            </div>
            <div v-else class="w-full flex flex-col justify-center items-center">
                <img src="@/assets/pizza_loading_1.gif" class="w-2/5" />
                <div class="p-8">загрузка...</div>
            </div>            
            
            <div>
                <div class="py-4 px-4 bg-yellow-100">
                    <div class="text-2xl px-4 text-center">Проверить статус заказа</div>
                    <div class="w-full overflow-x-auto flex flex-col py-4">
                        <input v-model="check_order_status.phone" class="py-3 pl-4 border w-full outline-none " placeholder="Номер Телефона" type="tel"/>
                        <input v-model="check_order_status.number" class="py-3 pl-4 border w-full outline-none " placeholder="Номер заказа" type="tel"/>
                        <div class="w-full p-4">
                            <div class="py-4 w-full rounded-md bg-yellow-300 text-center text-xl" @click="checkOrderStatus">🔥 проверить статус</div>
                        </div>
                    </div>
                    <div v-if="check_order_status.result.message" class="w-full overflow-x-auto flex flex-col py-4 text-lg text-center">
                        <div class="py-2 text-2xl">{{ check_order_status.result.message }}</div>
                        <div v-if="check_order_status.result.item" class="bg-white flex items-center w-full">
                            <div class="w-1/5 p-2">
                                <div class="text-4xl">{{ check_order_status.result.item.current_status.emoji }}</div>
                                <div>{{ check_order_status.result.item.current_status.name }}</div>
                            </div>
                            <div class="w-4/5 flex flex-col items-start p-4">
                                <div v-for="item in check_order_status.result.item.items" class="flex items-center w-full">
                                    <div class="w-1/12 text-left">
                                        <div v-if="item.is_combo" >🍱</div>
                                    </div>
                                    <div class="w-7/12 text-left">
                                        {{ item.name }}
                                    </div>
                                    <div class="w-4/12 text-left">
                                        x{{ item.amount }}
                                        <div v-if="item.is_bonus" >🎁</div>
                                    </div>
                                </div>
                                <div class="w-full text-right border-t">Итого: {{ check_order_status.result.item.total_summ }}</div>
                                <!-- <div>{{ check_order_status.result.item }}</div> -->
                            </div>
                        </div>
                    </div>
                </div>            
            </div>            
            
            <div v-if="issalesloaded">
                <div v-if="sales.length > 0" class="py-4 px-4 bg-gray-100">
                    <div class="text-2xl px-4 text-center">Акции Омлет</div>
                    <div class="w-full overflow-x-auto flex ">
                        <div v-for="item in sales" class="flex m-4 bg-white rounded-md" >
                            <!-- <router-link :to="{ name: 'Combos' }" class="w-full flex "> -->
                                <!-- <div :style="{ 'background': 'url('+combo.main_image?.url+') no-repeat'}" 
                                    style="background-position: left center; background-size: 140%"
                                    class="w-2/5">
                                </div> -->
                                <div class="w-full"  style="width: 240px">
                                    <div>
                                        <div v-if="item.main_image" class="flex">
                                        <img :src="item.main_image?.url" style="width: 240px" class="rounded-md rounded-bl-none rounded-br-none"/>
                                        </div>
                                        <div class="text-lg font-semibold p-2 pl-4">
                                            {{ item.title }}
                                        </div>
                                        <div v-if="item.expires_at && item.expires_at != '01.01.1970'" class="text-gray-500 pr-4 text-right">
                                            до: {{ item.expires_at }}
                                        </div>
                                        <!--
                                        <div class="text-gray-900">
                                            {{ item.body }}
                                        </div>
                                        <div class="text-gray-500">
                                            {{ item.created_at }}
                                        </div> -->
                                    </div>
                                </div>
                            <!-- </router-link> -->
                        </div>
                    </div>
                </div>
            </div>


            <div v-if="iscombosloaded" class="w-full flex flex-wrap py-4 px-4 justify-center">
                <router-link class="text-2xl py-8" :to="{ name: 'Combos', params: { branch_id: $store.getters.getBranch.id } }">Комбо по выгодным ценам</router-link>
                <div v-for="combo in combos" class="w-full mb-8 rounded-md shadow-md mx-4 bg-white flex " >
                    <router-link :to="{ name: 'Combos', params: { branch_id: $store.getters.getBranch.id } }" class="w-full flex ">
                        <div :style="{ 'background': 'url('+combo.main_image?.url+') no-repeat'}" 
                            style="background-position: left center; background-size: 140%"
                            class="w-2/5">
                        </div>
                        <div class="p-2 w-3/5">
                            <div>
                                <div class="text-lg">
                                    {{ combo.name }}
                                    <div class="text-yellow-500 text-xl">{{ combo.price }}тг</div>
                                    <div class="text-black text-md" style="text-decoration: line-through">{{ combo.full_price }}тг</div>
                                </div>
                                <div class="text-xs flex flex-wrap">
                                    <div v-for="meal in combo.meals" class="w-1/5">
                                        <div class="flex w-full items-center">
                                            <div class="rounded-full bg-gray-100 h-6 w-6"><img :src="meal.main_image?.url" class="rounded-full bg-gray-100" /></div>
                                            <!-- <div class="w-4/5 pl-1">{{ meal.name }}</div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <router-link class="text-lg pb-4 px-4 w-full" :to="{ name: 'Combos', params: { branch_id: $store.getters.getBranch.id } }">
                    <div class="rounded-md bg-yellow-300 py-3 px-4 w-full text-center">Посмотреть все комбо</div>
                </router-link>
            </div>
            <div v-else class="w-full flex flex-col justify-center items-center">
                <img src="@/assets/pizza_loading_1.gif" class="w-2/5" />
                <div class="p-8">загрузка...</div>
            </div>
            
            <div v-if="isnewsloaded">
                <div v-if="news.length > 0" class="w-full flex flex-wrap py-4 px-8">
                    <div class="text-lg text-gray-500 py-4">Новости Омлет</div>
                    <div v-for="item in news" class="w-full mb-8 bg-white flex " >
                        <!-- <router-link :to="{ name: 'Combos' }" class="w-full flex "> -->
                            <!-- <div :style="{ 'background': 'url('+combo.main_image?.url+') no-repeat'}" 
                                style="background-position: left center; background-size: 140%"
                                class="w-2/5">
                            </div> -->
                            <div class="w-full">
                                <div>
                                    <div class="text-lg font-semibold">
                                        {{ item.title }}
                                    </div>
                                    <div v-if="item.images.length > 0" class="flex overflow-x-auto">
                                       <image-galery :images="item.images" />
                                        <!-- <img v-for="image in item.images" :src="image.url" style="width: 180px"/> -->
                                    </div>
                                    <div class="text-gray-900">
                                        {{ item.body }}
                                    </div>
                                    <div class="text-gray-500">
                                        {{ item.created_at }}
                                    </div>
                                </div>
                            </div>
                        <!-- </router-link> -->
                    </div>
                </div>
                <!-- <router-link class="text-lg pb-4 px-4 w-full" :to="{ name: 'Combos' }">
                    <div class="rounded-md bg-yellow-300 py-3 px-4 w-full text-center">Посмотреть все</div>
                </router-link> -->
            </div>
            <div v-else class="w-full flex flex-col justify-center items-center">
                <img src="@/assets/pizza_loading_1.gif" class="w-2/5" />
                <div class="p-8">загрузка...</div>
            </div>

            <div class="w-full flex flex-col p-2">
                <div class="bg-white w-full">
                    <div class="w-full p-4 bg-white">
                        <router-link :to="{ name: 'RateOrder'}" ><div class="py-4 w-full rounded-full bg-green-300 text-center text-xl text-green-900">🔥 Отзыв о заказе</div></router-link>
                    </div>
                </div>
                <div class="text-lg text-gray-500 py-2 text-center">Оставьте свой отзыв о вашем заказе</div>
            </div>

            <div class="w-full flex flex-col p-2">
                <div class="bg-white w-full">
                    <div class="w-full p-4 bg-white">
                        <router-link :to="{ name: 'About'}" ><div class="py-4 w-full rounded-full bg-yellow-300 text-center text-xl text-yellow-900">🔥 О нас</div></router-link>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import api_route from '../router/api_routes';

export default {
    data: ()=> {
        return {
            istagsloaded: false,
            tags: [],
            iscombosloaded: false,
            combos: [],
            issalesloaded: false,
            sales: [],
            isnewsloaded: false,
            news: [],
            emoji: {
                'burger':'🍔', 
                'pizza':'🍕', 
                'soups':'🍲', 
                'drink':'☕', 
                'fastfood':'🍔', 
                'sushi':'🍣', 
                'barbeque':'🍖', 
                'set':'🍱'
            },
            branch: {
                id: 1,
                city: {
                    id: 1,
                    name: 'Павлодар',
                }
            },
            check_order_status: {
                phone: null,
                number: null,
                result: {
                    code: 0,
                    message: ''
                }
            }
        }
    },
    methods: {
        checkOrderStatus () {
            if (!this.check_order_status.number || !this.check_order_status.phone) return;
            
            axios
                .post(api_route('order_status'), { phone_number: this.check_order_status.phone, order_id: this.check_order_status.number })
                .then((res)=>{
                    this.check_order_status.result = res.data
                })
        },
        selectBranch(_branch){
            this.$store.commit('setBranch', _branch)
            this.getTags()
        },
        getTags(){
            this.istagsloaded = false
            axios
                .get(api_route('tags_all', [ this.$store.getters.getBranch.id]))
                .then((res)=>{
                    this.tags = res.data
                    this.istagsloaded = true

                    this.getCombos()
                    this.getNews()
                    this.getSales()
                })
        },
        getCombos(){
            this.iscombosloaded = false
            axios
                .get(api_route('combos_top', [ 5, this.$store.getters.getBranch.id]))
                .then((res)=>{
                    this.combos = res.data
                    this.iscombosloaded = true
                })
        },
        getNews(){
            this.isnewsloaded = false
            axios
                .get(api_route('news_latest', [ 5, this.$store.getters.getBranch.id]))
                .then((res)=>{
                    this.news = res.data
                })
                .finally(()=>this.isnewsloaded = true)
        },
        getSales(){
            this.issalesloaded = false
            axios
                .get(api_route('sales_active', [ this.$store.getters.getBranch.id]))
                .then((res)=>{
                    this.sales = res.data
                })
                .finally(()=>this.issalesloaded = true)
        },
        updateUrl(newUrl){
            this.$router.push(newUrl)
        }
    },
    mounted(){
        this.branch = this.$store.getters.getBranch

        this.getTags()

    }
}
</script>
<style scoped>
    .header__bg {
        background-image: url(https://storage.googleapis.com/multi-static-content/previews/artage-io-thumb-b984765ab2c1529ee3093637f67c64b1.png);
        background-repeat: no-repeat;
        background-position: top center;
        background-size: contain
    }
    .white__outline {
        text-shadow:
        -1px -1px 0 #fff,  
        1px -1px 0 #fff,
        -1px 1px 0 #fff,
        1px 1px 0 #fff;
    }
</style>