<template lang="">
    <div class="relative">
        <div class="absolute top-0 left-0 h-full w-1/2 z-2" @click="prevImage()"></div>
        <img ref="mainImage" :src="images[current_image_index].url" style="width: 100%" />
        <div class="absolute top-0 right-0 h-full w-1/2 z-2" @click="nextImage()"></div>
        <div v-if="images.length > 1" class="absolute bottom-0 right-0 w-full z-1 flex justify-center items-center py-2">
            <div class="rounded-full bg-black flex justify-center items-center p-1">
                <div v-for="index in images.length" class="h-2 w-2 rounded-full mx-1" :class="[ current_image_index == index -1 ? 'bg-yellow-300' : 'bg-white' ]"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'image-galery',
    data: () => {
        return {
            current_image_index: 0,
        }
    },
    props: {
        images: [Array]
    },
    methods: {
        prevImage(){
            if(this.current_image_index == 0){
                this.current_image_index = this.images.length - 1
            } else {
                this.current_image_index--
            }
        },
        nextImage(){
            if(this.current_image_index == this.images.length - 1){
                this.current_image_index = 0
            } else {
                this.current_image_index++
            }
        }
    }
}
</script>
<style scoped>
</style>