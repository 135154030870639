import { createStore } from "vuex";

export default createStore({
    state: {
        // состояния
        user: null,
        address: null,
        basketItems: [],
        branch: 
            { id: 1, 
                city: {
                    id: 1,
                    name: 'Павлодар'
                }
            },
        branches: [
            { id: 1, 
                city: {
                    id: 1,
                    name: 'Павлодар'
                }
            },
            { id: 2, 
                city: {
                    id: 2,
                    name: 'Аксу'
                }
            }
        ]
    },
    getters: {
        // получить состояния
        basketItems(state) {
            return state.basketItems
        },
        countItemInBasket: (state) => (meal_id) => {
            return state.basketItems.find(item => item.meal_id === meal_id)
        },
        basketSumm(state){
            let summ = 0
            state.basketItems.forEach(item => {
                summ += item.amount * item.price
            })
            return summ
        },
        getBranch(state){
            return state.branch
        },
        getBranches(state){
            return state.branches
        },
    },
    mutations: {
        // функции в которых меняем состояние
        increaseAmount(state, item) {
            return item.amount++
        },
        decreaseAmount(state, _item) {
            if(_item.amount > 1) return _item.amount--
            return state.basketItems.splice(state.basketItems.findIndex(item => item === _item), 1)
        },
        removeFromBasket(state, payload) {
            let meal = payload.item
            let is_combo = payload.is_combo
            let details = payload.details
            let _bmeal = state.basketItems.find(item => item.meal_id === meal.id && item.is_combo == is_combo && item.details == details)
            if(_bmeal && _bmeal.amount > 1) return _bmeal.amount--
            return state.basketItems.splice(state.basketItems.findIndex(item => item.meal_id === meal.id && item.is_combo == is_combo && item.details == details), 1)
        },
        addToBasket(state, payload) {

            let _item = {
                meal_id: payload.item.id,
                name: payload.item.name,
                is_combo: payload.is_combo,
                is_bonus: 0,
                amount: 1,
                price: payload.item.price,
                details: payload.details || '',
                image: payload.item.main_image?.url,
            }
            let _bmeal = state.basketItems.find(item => item.meal_id === _item.meal_id && item.is_combo == _item.is_combo && item.details == _item.details)
            if(_bmeal) return _bmeal.amount++
            return state.basketItems.push(_item)
        },
        clearBasket(state){
            state.basketItems = []
            return
        },
        loginUser(){
            
        },
        setBranch(state, _branch){
            state.basketItems = []
            return state.branch = _branch
        }
    },
    actions: {
        // функции с мутациями
        // например, запрос на сервер, а потом мутация для сохранения состояния
    },
    modules: {
        // весь код можно поменять на части
        //post: PostModule
    }
})