import { createRouter, createWebHistory } from "vue-router";
import Main from '@/pages/Main';
import MealsByTag from '@/pages/MealsByTag';
import Combos from '@/pages/CombosList';
import Basket from '@/pages/Basket';
import RateOrder from '@/pages/RateOrder';
import About from '@/pages/About';

const routes = [
    {
        path: '/:pathMatch(.*)*',
        component: Main,
        alias: ['/section'],
        props: true,
    },
    {
        path: '/:branch_id/:tag_slug\.menu',
        name: 'MealsByTag',
        meta: { hasbasket: true },
        component: MealsByTag,
    },
    {
        path: '/:branch_id/combos',
        name: 'Combos',
        meta: { hasbasket: true },
        component: Combos,
    },
    {
        path: '/basket',
        name: 'Basket',
        meta: { hasbasket: true },
        component: Basket,
    },
    {
        path: '/rateorder',
        name: 'RateOrder',
        meta: { hasbasket: false },
        component: RateOrder,
    },
    {
        path: '/about',
        name: 'About',
        meta: { hasbasket: false },
    },
]

const router = createRouter({
    routes,
    history: createWebHistory(),
    scrollBehavior (to, from, savedPosition) {
        return {top: 0}
    }
});

export default router;