<template lang="">
    <input :value="modelValue" @input="updateInput" autocomplete="off" type="tel" />
</template>
<script>
export default {
    name: 'my-phone-input',
    props: {
        modelValue: [String, Number]
    },
    methods: {
        updateInput(event) {
            let value = event.target.value;
            value = this.cleanValue(value)
            this.setValue(value)
        },
        setValue(value){
            this.$emit('update:modelValue', value)
            this.$emit('change')
        },
        cleanValue(value){
            value = value.replaceAll("(", "");
            value = value.replaceAll(")", "");
            value = value.replaceAll("-", "");
            value = value.replaceAll(" ", "");
            value = value.replaceAll("+", "");
            value = value.replaceAll(" ", "");
            value = value.replaceAll("/", "");
            value = value.replaceAll("#", "");
            value = value.replaceAll("*", "");
            if(value[0] == '7' && value.length == 11)  value = value.replace('7', '8');
            return value;
        },
    }
}
</script>
<style scoped>
    input:focus{
        border-bottom: 1px solid #333
    }
</style>